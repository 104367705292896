import React from "react";

import styled from "styled-components";
function CompanyLogos() {
  return (
    <>
      {" "}
      <div className="px-4 mx-auto text-center md:max-w-screen-md lg:max-w-screen-lg lg:px-36">
        <span className="font-semibold font-silka text-gray-400 uppercase">
          Available on the World&lsquo;s top Job Platforms
        </span>
        <div className="flex flex-wrap justify-center items-center mt-8 text-gray-500 sm:justify-between">
          <AnimationDiv>
            <div className="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-white ">
              <h2 className="text-5xl font-bold font-silka ">Greenhouse</h2>
            </div>
            <div className="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
              <h2 className="text-5xl font-bold font-silka ">Lever</h2>
            </div>
            <div className="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
              <h2 className="text-5xl font-bold font-silka ">Workable</h2>
            </div>
            <div className="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
              <h2 className="text-5xl font-bold font-silka ">BreezyHR</h2>
            </div>
            <div className="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
              <h2 className="text-5xl font-bold font-silka ">BambooHR</h2>
            </div>
            <div className="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
              <h2 className="text-5xl font-bold font-silka ">
                SmartRecruiters
              </h2>
            </div>{" "}
            <div className="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
              <h2 className="text-5xl font-bold font-silka ">Recruitee</h2>
            </div>
            <div className="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
              <h2 className="text-5xl font-bold font-silka ">AshbyHQ</h2>
            </div>
            <div className="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
              <h2 className="text-5xl font-bold font-silka ">Greenhouse</h2>
            </div>
            <div className="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
              <h2 className="text-5xl font-bold font-silka ">Lever</h2>
            </div>
            <div className="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
              <h2 className="text-5xl font-bold font-silka ">Workable</h2>
            </div>
            <div className="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
              <h2 className="text-5xl font-bold font-silka ">BreezyHR</h2>
            </div>
            <div className="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
              <h2 className="text-5xl font-bold font-silka ">BambooHR</h2>
            </div>
          </AnimationDiv>
        </div>
      </div>
    </>
  );
}
const AnimationDiv = styled.div`
  white-space: nowrap;
  width: 100vw;
  -webkit-animation: tickerScroll 40s linear infinite normal;
  animation: tickerScroll 40s linear infinite normal;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  opacity: 0;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  transition: all linear;
  will-change: transform, opacity;
  display: flex;
  flex-direction: row;
  gap: 60px;
  @keyframes tickerScroll {
    0% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    to {
      opacity: 1;
      -webkit-transform: translateX(-1937px);
      transform: translateX(-1937px);
    }
  }
`;
export default CompanyLogos;
