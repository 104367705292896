import Head from "next/head";

import Link from "next/link";
import { useRouter } from "next/router";
import { useContext } from "react";

import CompanyLogos from "@/components/CompanyLogos";
import Footer from "@/components/Footer";
import Layout from "@/components/layout";
import { AuthContext } from "../context/AuthContext";

import { MdArrowOutward } from "react-icons/md";

export default function Home() {
  const router = useRouter();
  const user = useContext(AuthContext);
  //add canonical tag
  const canonicalUrl = (
    `https://instantapply.co` + (router.asPath === "/" ? "" : router.asPath)
  ).split("?")[0];

  return (
    <>
      <Layout>
        <Head>
          <title>InstantApply</title>
          <meta
            name="title"
            property="og:title"
            content="Analyze your Resume and Autofill job applications easily"
          />

          <meta name="keywords" content="jobs,cv,resume " />
          <meta
            name="description"
            content="Analyze your Resume and Autofill job applications easily"
          />
          <link rel="icon" href="/favicon.ico" />

          <link rel="canonical" href={canonicalUrl} key="canonical" />
          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website" />

          <meta property="og:url" content="https://instantapply.co" />
          <meta property="og:title" content="instantapply.co" />
          <meta
            name="description"
            property="og:description"
            content="Analyze your Resume and Autofill job applications easily"
          />
          <meta property="og:site_name" content="instantapply.co" />
          <meta
            name="image"
            property="og:image"
            content="https://yauoagpuebtzbgpptwsp.supabase.co/storage/v1/object/public/image/instantapply-image.png"
          />
          {/* Twitter */}
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://instantapply.co" />
          <meta property="twitter:site" content="@uiland" />
          <meta
            property="twitter:title"
            content="Analyze your Resume and Autofill job applications easily"
          />
          <meta
            property="twitter:description"
            content="Analyze your Resume and Autofill job applications easily"
          />
          <meta
            property="twitter:image"
            content="https://yauoagpuebtzbgpptwsp.supabase.co/storage/v1/object/public/image/instantapply-image.png"
          />
          <meta name="next-head-count" content="23" />

          <meta
            name="google-site-verification"
            content="blSfmOOE3TZH4oMIOHPHxYYV9qW83ZLuUUgH-vADBxY"
          />
        </Head>
        <>
          {/* <a
            href="https://www.producthunt.com/posts/instantapply?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-instantapply"
            target="_blank"
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=417132&theme=light"
              alt="Instantapply - Analyze your resume and Autofill your resume in one click | Product Hunt"
              style={{ width: "250px", height: "54px" }}
              width="250"
              height="54"
            />
          </a> */}
          <div className="relative overflow-hidden ">
            <div className="absolute bg-gradient-radial w-full h-[398px] ">
              <img src="../assets/images/grid.svg" alt="grid" />
            </div>
            <section className="bg-instant-light-100 dark:bg-gray-900 ">
              <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12 relative z-50">
                {/* <a href="#" className="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-instant-600 bg-gray-100 rounded-full dark:bg-gray-800 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700" role="alert">
            <span className="text-xs bg-primary-600 rounded-full text-white px-4 py-1.5 mr-3">New</span> <span className="text-sm font-medium">InstantApply is out! See what&apos;s new</span> 
            <svg className="ml-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
        </a> */}
                <h1 className="mb-4 text-4xl font-extrabold font-silka tracking-tight leading-tight text-instant-600 md:text-5xl lg:text-8xl px-5 dark:text-white">
                  Land Your Dream Job <br />
                  <span className="text-instant-text">Faster and Easier</span>
                </h1>
                {/* <p className="mb-8 text-lg  text-instant-textgrey font-silka font-medium lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">
            Apply to job applications faster with instant autofill{" "}
          </p> */}
                <p className="mb-8 text-lg  text-instant-textgrey font-silka font-medium  sm:px-16 xl:px-60 dark:text-gray-400">
                  Land your dream role with our system for job applicants,
                  including Resume analyzer, Job autofill , Job tracking and AI
                  Coverletter generation{" "}
                </p>
                <div className="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
                  <a
                    target="_blank"
                    href="https://chrome.google.com/webstore/detail/instant-apply/ihgmeifeofnbegocgfecndeaohbigpon"
                    className="bg-instant-600 hover:bg-primary-800 text-white focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900 inline-flex justify-center font-silka items-center py-3 px-5 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                  >
                    <svg
                      className="mr-2 -ml-1 w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path>
                    </svg>
                    Add to Chrome (it&lsquo;s free)
                  </a>
                  {!user ? (
                    <Link
                      href="/signin"
                      className="inline-flex cursor-pointer font-silka justify-center items-center py-3 px-5 text-base font-medium text-center text-black rounded-lg bg-white hover:bg-white focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
                    >
                      Get Started For Free
                      <svg
                        className="ml-2 -mr-1 w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </Link>
                  ) : null}
                </div>
                <CompanyLogos />
              </div>
            </section>

            <section className="bg-gradient-halfcream mb-6 ">
              <div
                style={{
                  position: "relative",
                  paddingBottom: "56.25%",
                  height: 0,
                }}
                className="py-8 px-4 mx-auto max-w-screen-lg lg:px-12 sm:text-center lg:py-16 relative z-50"
              >
                <iframe
                  src="https://www.youtube.com/embed/UY_T9dUGoy4?si=pOnT9-P0StXQwV7k"
                  title="instant-loom-video"
                  allowFullScreen
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                  }}
                ></iframe>
              </div>
            </section>

            <section className="bg-instant-light-100 dark:bg-gray-900">
              <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                <div className="max-w-screen-md mb-8 lg:mb-16">
                  <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-instant-text dark:text-white font-silka">
                    Features
                  </h2>
                  <p className="text-gray-500 sm:text-xl dark:text-gray-400 font-silka font-medium">
                    Here at Instantapply we focus on technologies that ensure
                    you land your dream role
                  </p>
                </div>
                <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
                  <div>
                    <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                      <svg
                        className="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                          clipRule="evenodd"
                        ></path>
                        <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"></path>
                      </svg>
                    </div>
                    <h3 className="mb-2 text-xl font-bold text-instant-text font-silka underline">
                      <a
                        href="https://instantapply.co/resume-analyzer"
                        target="_blank"
                        className="flex items-center"
                      >
                        Resume Keyword Analyzer
                        <span className="ml-2 w-6 h-6 md:h-4 md:w-4">
                          <MdArrowOutward />
                        </span>
                      </a>
                    </h3>

                    <p className="text-gray-500 dark:text-gray-400 font-silka font-medium">
                      {" "}
                      Analyze job descriptions and identify keywords missing
                      from your resume.
                    </p>
                  </div>
                  <div>
                    <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                      <svg
                        className="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                          clipRule="evenodd"
                        ></path>
                        <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"></path>
                      </svg>
                    </div>
                    <h3 className="mb-2 text-xl font-bold text-instant-text font-silka underline">
                      <a
                        href="https://instantapply.co/ai"
                        target="_blank"
                        className="flex items-center"
                      >
                        AI Cover letter generation
                        <span className="ml-2 w-6 h-6 md:h-4 md:w-4">
                          <MdArrowOutward />
                        </span>
                      </a>
                    </h3>

                    <p className="text-gray-500 dark:text-gray-400 font-silka font-medium">
                      {" "}
                      InstantApply&lsquo;s AI-driven cover letter generator
                      crafts personalized, captivating cover letters that speak
                      directly to hiring managers.
                    </p>
                  </div>
                  <div>
                    <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                      <svg
                        className="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                          clipRule="evenodd"
                        ></path>
                        <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"></path>
                      </svg>
                    </div>
                    <h3 className="mb-2 text-xl font-bold text-instant-text font-silka underline">
                      <a
                        href="https://instantapply.co/jobs"
                        target="_blank"
                        className="flex items-center"
                      >
                        Job search
                        <span className="ml-2 w-6 h-6 md:h-4 md:w-4">
                          <MdArrowOutward />
                        </span>
                      </a>
                    </h3>

                    <p className="text-gray-500 dark:text-gray-400 font-silka font-medium">
                      {" "}
                      InstantApply&lsquo;s Job board showcases recent remote jobs from the world , making it easier to search for your dream job
                    </p>
                  </div>
                  <div>
                    <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                      <svg
                        className="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                          clipRule="evenodd"
                        ></path>
                        <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"></path>
                      </svg>
                    </div>
                    <h3 className="mb-2 text-xl font-bold text-instant-text font-silka underline">
                      <a
                        href="https://instantapply.co/talent"
                        target="_blank"
                        className="flex items-center"
                      >
                        Talent Pool
                        <span className="ml-2 w-6 h-6 md:h-4 md:w-4">
                          <MdArrowOutward />
                        </span>
                      </a>
                    </h3>

                    <p className="text-gray-500 dark:text-gray-400 font-silka font-medium">
                      {" "}
                      InstantApply&lsquo;s Talent pool matches Companies with their preferred candidate using AI to filter out the perfect talent for the hire
                    </p>
                  </div>
                  <div>
                    <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                      <svg
                        className="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11.707 4.707a1 1 0 00-1.414-1.414L10 9.586 8.707 8.293a1 1 0 00-1.414 0l-2 2a1 1 0 101.414 1.414L8 10.414l1.293 1.293a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <h3 className="mb-2 text-xl font-bold text-instant-text font-silka">
                      Job Autofill
                    </h3>
                    <p className="text-gray-500 dark:text-gray-400 font-silka font-medium">
                      Spend less time on tedious forms and more time on
                      showcasing your brilliance to employers.
                    </p>
                  </div>
                  <div>
                    <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                      <svg
                        className="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z"></path>
                      </svg>
                    </div>
                    <h3 className="mb-2 text-xl font-bold text-instant-text font-silka">
                      Job Tracking
                    </h3>
                    <p className="text-gray-500 dark:text-gray-400 font-silka font-medium">
                      Track the progress of your applications effortlessly.No
                      more uncertainty or missed opportunities – just a
                      crystal-clear view of your job hunt&lsquo;s trajectory..
                    </p>
                  </div>

                  <div>
                    <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                      <svg
                        className="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                          clipRule="evenodd"
                        ></path>
                        <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"></path>
                      </svg>
                    </div>
                    <h3 className="mb-2 text-xl font-bold text-instant-text font-silka">
                      Analytics
                    </h3>
                    <p className="text-gray-500 dark:text-gray-400 font-silka font-medium">
                      {" "}
                      Track your job search progress through insightful metrics.
                    </p>
                  </div>
                  <div>
                    <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                      <svg
                        className="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                          clipRule="evenodd"
                        ></path>
                        <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"></path>
                      </svg>
                    </div>
                    <h3 className="mb-2 text-xl font-bold text-instant-text font-silka">
                      Activity Tracking
                    </h3>
                    <p className="text-gray-500 dark:text-gray-400 font-silka font-medium">
                      {" "}
                      View a full timeline of all your job search activities
                      from application to offer accepted.
                    </p>
                  </div>
                  <div>
                    <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                      <svg
                        className="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                          clipRule="evenodd"
                        ></path>
                        <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"></path>
                      </svg>
                    </div>
                    <h3 className="mb-2 text-xl font-bold text-instant-text font-silka">
                      Email Finder
                    </h3>
                    <p className="text-gray-500 dark:text-gray-400 font-silka font-medium">
                      {" "}
                      Find all email addresses on any domain as fast as a
                      lightning bolt.
                    </p>
                  </div>
                  <div>
                    <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                      <svg
                        className="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                          clipRule="evenodd"
                        ></path>
                        <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"></path>
                      </svg>
                    </div>
                    <h3 className="mb-2 text-xl font-bold text-instant-text font-silka">
                      Gmail Template Email AutoFill
                    </h3>
                    <p className="text-gray-500 dark:text-gray-400 font-silka font-medium">
                      {" "}
                      Store and Send your cold mails to recruiters faster with
                      our Gmail integration
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </>
      </Layout>
    </>
  );
}

// export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
//   // Create authenticated Supabase Client
//   const supabase = createServerSupabaseClient(ctx);
//   // Check if we have a session
//   const {
//     data: { session },
//   } = await supabase.auth.getSession();
//   //get user belonging to that session

//   const user = session ? session.user : null;

//   const result = await getUserOnboardingStatus(user);
//   if (result && result[0].onboarding === 2) {
//     return {
//       redirect: {
//         destination: "/dashboard",
//         permanent: false,
//       },
//     };
//   } else if (result && result[0].onboarding !== 2) {
//     return {
//       redirect: {
//         destination: "/onboarding",
//         permanent: false,
//       },
//     };
//   }

//   return {
//     props: { session },
//   };
// };
